import React from "react";
import { Link } from "react-router-dom";
import HeaderTop from "./HeaderTop";

const Header = (props) => {
  const headerType = props.headerType ? props.headerType : "";
  return (
    <React.Fragment>
      {/* HEADER AREA */}
      <header className="rts-header style-one header__default">
        <HeaderTop />

        <div className="container">
          <div className="row">
            <div className="rts-header__wrapper">
              {/* FOR LOGO */}
              <div className="rts-header__logo">
                <a href="/" className="site-logo">
                  <img
                    className="logo-white"
                    src="/images/white-logo.png"
                    alt="logo"
                    width={170}
                  />
                  <img
                    className="logo-dark"
                    src="/images/logo.png"
                    alt="logo"
                    width={170}
                  />
                </a>
              </div>
              {/* FOR NAVIGATION MENU */}
              <nav className="rts-header__menu" id="mobile-menu">
                <div className="hostie-menu">
                  <ul className="list-unstyled hostie-desktop-menu">
                    <li className="menu-item">
                      <a href="/" className="hostie-dropdown-main-element">
                        Home
                      </a>
                    </li>

                    <li className="menu-item hostie-has-dropdown mega-menu">
                      <a href="#" className="hostie-dropdown-main-element">
                        Services
                      </a>
                      <div className="rts-mega-menu">
                        <div className="wrapper">
                          <div className="row g-0">
                            <div className="col-lg-6">
                              <ul className="mega-menu-item">
                                <li>
                                  <a href="/transactional-sms">
                                    <img
                                      src="assets/images/icon/alert-sms.png"
                                      alt="icon"
                                      width={50}
                                    />
                                    <div className="info">
                                      <p>Transactional SMS</p>
                                      <span>Information & Alert SMS</span>
                                    </div>
                                  </a>
                                </li>
                                <li>
                                  <a href="/otp-sms">
                                    <img
                                      src="assets/images/icon/otp-sms.png"
                                      alt="icon"
                                      width={50}
                                    />
                                    <div className="info">
                                      <p>OTP SMS</p>
                                      <span>Two Factor Authentication SMS</span>
                                    </div>
                                  </a>
                                </li>
                                <li>
                                  <a href="/promotional-sms">
                                    <img
                                      src="assets/images/icon/sms.png"
                                      alt="icon"
                                      width={50}
                                    />
                                    <div className="info">
                                      <p>Promotional SMS</p>
                                      <span>Marketing & Offers SMS</span>
                                    </div>
                                  </a>
                                </li>
                              </ul>
                            </div>
                            <div className="col-lg-6">
                              <ul className="mega-menu-item">
                                <li>
                                  <a href="/sms-reseller">
                                    <img
                                      src="assets/images/icon/web.png"
                                      alt="icon"
                                      width={50}
                                    />
                                    <div className="info">
                                      <p>SMS Reseller</p>
                                      <span>Start Your Own SMS Website</span>
                                    </div>
                                  </a>
                                </li>
                                <li>
                                  <a href="/sms-gateway-software">
                                    <img
                                      src="assets/images/icon/web-programming.png"
                                      alt="icon"
                                      width={50}
                                    />
                                    <div className="info">
                                      <p>SMS Gateway Software</p>
                                      <span>Fully Customizable Software</span>
                                    </div>
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li className="menu-item">
                      <a
                        href="/bulk-sms-pricing"
                        className="hostie-dropdown-main-element"
                      >
                        Pricing
                      </a>
                    </li>
                    <li className="menu-item">
                      <a
                        href="https://docs.formax.cloud"
                        target="_blank"
                        className="hostie-dropdown-main-element"
                      >
                        Developer API
                      </a>
                    </li>
                    <li className="menu-item hostie-has-dropdown">
                      <a className="hostie-dropdown-main-element">Company</a>
                      <ul className="hostie-submenu list-unstyled menu-pages">
                        <li className="nav-item">
                          <a className="nav-link" href="/about-formax-cloud">
                            About Us
                          </a>
                        </li>
                        <li className="nav-item">
                          <a className="nav-link" href="/contact-us">
                            Contact Us
                          </a>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </div>
              </nav>
              {/* FOR HEADER RIGHT */}
              <div className="rts-header__right">
                <a
                  href="https://app.formax.cloud/#/signup"
                  className="login__btn"
                  target="_blank"
                >
                  SIGN UP
                </a>
                <button
                  id="menu-btn"
                  aria-label="Menu"
                  className="mobile__active menu-btn"
                >
                  <i className="fa-sharp fa-solid fa-bars" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </header>
      {/* HEADER AREA END */}
    </React.Fragment>
  );
};

export default Header;
