import React from "react";

import Header from "../Templete/Header";
import HeroBanner from "./HeroBanner";
import Footer from "../Templete/Footer";
import BrandArea from "./BrandArea";
import OurServices from "./OurServices";
import Faq from "./Faq";
import SignupBanner from "./SignupBanner";
import Testimonial from "./Testimonial";
import OurPricing from "./OurPricing";
import OurFeatures from "./OurFeatures";

const Home = () => {
  return (
    <React.Fragment>
      <Header />
      <HeroBanner />
      <BrandArea />
      <OurServices />
      <OurPricing />
      <OurFeatures />
      <Testimonial />
      <Faq />
      <SignupBanner />
      <Footer />
    </React.Fragment>
  );
};

export default Home;
