import React from "react";

import Footer from "../Templete/Footer";
import Header from "../Templete/Header";

const PrivacyPolicy = () => {
  return (
    <>
      <Header />

      <div className="rts-hosting-banner rts-hosting-banner-bg banner-default-height">
        <div className="container">
          <div className="row">
            <div className="banner-area">
              <div className="rts-hosting-banner rts-hosting-banner__content w-530">
                <span className="starting__price">Privacy Policy </span>
                <h1 className="banner-title">Privacy Policy</h1>
                <p className="slogan"></p>
              </div>
              <div className="rts-hosting-banner__image">
                <img src="/images/privacy-policy.png" alt="" width={550} />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="rts-knowledgebase pt--120">
        <div class="container">
          <div class="tab-content" id="myTabContent">
            <div
              class="tab-pane fade active show knowledge__content"
              id="share-hosting-tab"
              role="tabpanel"
              aria-labelledby="share-hosting"
            >
              <h3>Privacy Policy</h3>
              <p>Effective Date: 01.01.2023</p>

              <p>
                Formax IT Solutions Private Limited ("we", "us", or "our")
                operates the website{" "}
                <a href="https://www.formax.cloud">www.formax.cloud</a> (the
                "Website"). This Privacy Policy explains how we collect, use,
                disclose, and safeguard your information when you visit our
                Website. By accessing or using our Website, you agree to the
                terms of this Privacy Policy.
              </p>

              <h5>1. Information We Collect</h5>

              <h6>a. Personal Data</h6>
              <p>
                When you use our Website, we may collect personally identifiable
                information that you voluntarily provide to us. This includes,
                but is not limited to:
              </p>
              <ul>
                <li>Name</li>
                <li>Email address</li>
                <li>Phone number</li>
                <li>Mailing address</li>
                <li>Company name</li>
                <li>
                  Any other personal data you provide via our forms or email
                  communication
                </li>
              </ul>

              <h6>b. Non-Personal Data</h6>
              <p>
                We may collect non-personal information automatically as you
                navigate through the Website. This may include:
              </p>
              <ul>
                <li>IP Address</li>
                <li>Browser type</li>
                <li>Operating system</li>
                <li>Referring URL</li>
                <li>Pages viewed on the Website</li>
                <li>Time spent on pages</li>
              </ul>

              <h5>2. How We Use Your Information</h5>
              <p>
                We may use the information collected from you in the following
                ways:
              </p>
              <ul>
                <li>To personalize your experience on the Website</li>
                <li>To improve our Website and services</li>
                <li>
                  To respond to your inquiries, comments, or support requests
                </li>
                <li>
                  To send periodic emails related to your interest in our
                  services
                </li>
                <li>
                  To process transactions or service requests made through the
                  Website
                </li>
                <li>For internal record keeping and data analysis purposes</li>
              </ul>

              <h5>3. How We Protect Your Information</h5>
              <p>
                We implement a variety of security measures to maintain the
                safety of your personal information. However, no data
                transmission over the Internet can be guaranteed as 100% secure.
                While we strive to protect your personal data, we cannot
                guarantee the security of any information you transmit to us,
                and you do so at your own risk.
              </p>

              <h5>4. Sharing Your Information</h5>
              <p>
                We do not sell, trade, or rent your personal information to
                others. We may share information with third parties in the
                following circumstances:
              </p>
              <ul>
                <li>
                  With trusted service providers who assist us in operating our
                  Website, conducting business, or servicing you, so long as
                  those parties agree to keep this information confidential
                </li>
                <li>If required by law or to comply with legal process</li>
                <li>
                  To protect our rights, privacy, safety, or property, or that
                  of others
                </li>
                <li>
                  In connection with any merger, sale of company assets, or
                  acquisition, your information may be transferred as part of
                  the business assets
                </li>
              </ul>

              <h5>5. Cookies</h5>
              <p>
                We use cookies to enhance your experience on our Website.
                Cookies are small data files stored on your browser when you
                visit our site. These cookies allow us to:
              </p>
              <ul>
                <li>Recognize you when you return to our site</li>
                <li>Understand and save your preferences for future visits</li>
                <li>
                  Compile aggregate data about site traffic and interaction to
                  improve our site’s usability and performance
                </li>
              </ul>
              <p>
                You can choose to disable cookies through your browser settings,
                but this may affect your ability to use certain features of the
                Website.
              </p>

              <h5>6. Third-Party Links</h5>
              <p>
                Our Website may contain links to third-party websites. These
                sites have separate and independent privacy policies. We have no
                responsibility or liability for the content or activities of
                these linked sites. We encourage you to review the privacy
                policies of any third-party websites you visit.
              </p>

              <h5>7. Your Rights</h5>
              <p>
                You have the right to access, correct, or delete any personal
                information that we hold about you. You may also withdraw your
                consent to our use of your information at any time. To exercise
                these rights, please contact us at the information provided
                below.
              </p>

              <h5>8. Children's Privacy</h5>
              <p>
                Our Website is not directed to individuals under the age of 18.
                We do not knowingly collect personal information from children.
                If we become aware that a child under 18 has provided us with
                personal information, we will take steps to delete such
                information.
              </p>

              <h5>9. Changes to This Privacy Policy</h5>
              <p>
                We may update this Privacy Policy from time to time. We will
                notify you of any changes by posting the new Privacy Policy on
                this page. Changes will be effective immediately upon posting.
              </p>

              <h5>10. Contact Us</h5>
              <p>
                If you have any questions about this Privacy Policy, please
                contact us by email: support@formax.cloud.
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default PrivacyPolicy;
