import React from "react";

const BrandArea = () => {
  return (
    <>
      {/* BRAND AREA */}
      <div className="rts-brand rts-brand__bg--section pt-100 pb-120">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="rts-brand__wrapper">
                <div className="rts-brand__wrapper--text">
                  <h5
                  // data-sal="slide-down"
                  // data-sal-delay={300}
                  // data-sal-duration={800}
                  >
                    Messaging Solutions Trusted by 2,000+ Businesses
                  </h5>
                  <div
                    className="rts-brand__wrapper--text-review"
                    // data-sal="slide-down"
                    // data-sal-delay={400}
                    // data-sal-duration={800}
                  >
                    <div className="review__company">
                      Supporting Over a Million Messages Delivered Daily
                    </div>
                  </div>
                </div>
                <div className="rts-brand__slider">
                  <div className="swiper-wrapper">
                    <div className="swiper-slide">
                      <div className="rts-brand__slider--single">
                        <a href="#" aria-label="brand-link">
                          <img
                            src="/images/clients/nexxture-logo.png"
                            alt=""
                            width={80}
                          />
                        </a>
                      </div>
                    </div>
                    <div className="swiper-slide">
                      <div className="rts-brand__slider--single">
                        <a href="#" aria-label="brand-link">
                          <img
                            src="/images/clients/formax-pay-logo.png"
                            alt=""
                            width={100}
                          />
                        </a>
                      </div>
                    </div>
                    <div className="swiper-slide">
                      <div className="rts-brand__slider--single">
                        <a href="#" aria-label="brand-link">
                          <img
                            src="/images/clients/wbstcb-logo.gif"
                            width={150}
                            alt=""
                          />
                        </a>
                      </div>
                    </div>
                    <div className="swiper-slide">
                      <div className="rts-brand__slider--single">
                        <a href="#" aria-label="brand-link">
                          <img
                            src="/images/clients/rupee-cash-logo.png"
                            alt=""
                            width={120}
                          />
                        </a>
                      </div>
                    </div>
                    <div className="swiper-slide">
                      <div className="rts-brand__slider--single">
                        <a href="#" aria-label="brand-link">
                          <img
                            src="/images/clients/grameen-bank-logo.png"
                            alt=""
                            width={120}
                          />
                        </a>
                      </div>
                    </div>
                    <div className="swiper-slide">
                      <div className="rts-brand__slider--single">
                        <a href="#" aria-label="brand-link">
                          <img
                            src="/images/clients/accutax-logo.png"
                            alt=""
                            width={100}
                          />
                        </a>
                      </div>
                    </div>
                    <div className="swiper-slide">
                      <div className="rts-brand__slider--single">
                        <a href="#" aria-label="brand-link">
                          <img
                            src="/images/clients/recharge-india-logo.png"
                            alt=""
                            width={70}
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* BRAND AREA END*/}
    </>
  );
};

export default BrandArea;
