import React from "react";

import Footer from "../Templete/Footer";
import Header from "../Templete/Header";
import SignupBanner from "../Home/SignupBanner";

const TransactionalSms = () => {
  return (
    <>
      <Header />

      <div className="rts-hosting-banner rts-hosting-banner-bg banner-default-height">
        <div className="container">
          <div className="row">
            <div className="banner-area">
              <div className="rts-hosting-banner rts-hosting-banner__content w-530">
                <span className="starting__price">Transactional SMS </span>
                <h4 className="banner-title">Transactional SMS</h4>
                <p className="slogan">
                  Real-Time, Secure, and Reliable SMS Notifications for Critical
                  Transactions
                </p>
              </div>
              <div className="rts-hosting-banner__image">
                <img
                  src="/images/promotional-sms-banner.png"
                  alt=""
                  width={550}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="rts-knowledgebase pt--120 pb--120">
        <div class="container">
          <div class="tab-content" id="myTabContent">
            <div
              class="tab-pane fade active show knowledge__content"
              id="share-hosting-tab"
              role="tabpanel"
              aria-labelledby="share-hosting"
            >
              <h4>Transaction SMS - Secure and Timely Notifications</h4>
              <p>
                Keep your users informed with instant and reliable Transaction
                SMS services provided by <strong>Formax Cloud</strong>. Our
                Transaction SMS platform ensures that your customers receive
                real-time updates on transactions, payments, and other critical
                activities, enhancing transparency and trust.
              </p>

              <h4>Key Benefits of Our Transaction SMS Service</h4>
              <ul>
                <li>
                  <strong>Instant Delivery:</strong> Our system ensures
                  immediate delivery of SMS notifications for every transaction,
                  ensuring users are always informed.
                </li>
                <li>
                  <strong>High Security:</strong> Transaction messages contain
                  sensitive information, and our platform ensures these messages
                  are delivered through secure channels, protecting your users
                  from fraud.
                </li>
                <li>
                  <strong>24/7 Service:</strong> Our Transaction SMS service is
                  available round the clock, ensuring users receive important
                  updates at any time.
                </li>
                <li>
                  <strong>Global Reach:</strong> We provide reliable transaction
                  SMS services to both domestic and international customers,
                  ensuring global connectivity.
                </li>
                <li>
                  <strong>Compliance with DLT Regulations:</strong> Our platform
                  is fully compliant with Indian DLT (Distributed Ledger
                  Technology) regulations, ensuring lawful and secure
                  communication.
                </li>
                <li>
                  <strong>Detailed Reporting:</strong> Get real-time insights
                  into your SMS campaign's performance with comprehensive
                  reports on delivery status and user engagement.
                </li>
              </ul>

              <h4>Types of Transaction SMS</h4>
              <p>
                We cater to a variety of industries, providing transactional SMS
                services for:
              </p>
              <ul>
                <li>
                  Banking and Financial Services: Real-time alerts on account
                  activities, payments, and withdrawals.
                </li>
                <li>
                  E-Commerce: Order confirmations, shipping updates, and payment
                  receipts.
                </li>
                <li>
                  Healthcare: Appointment confirmations and billing
                  notifications.
                </li>
                <li>
                  Utility Providers: Bill payment confirmations and due date
                  alerts.
                </li>
                <li>
                  Government Services: Alerts for application status, payments,
                  and other essential notifications.
                </li>
              </ul>

              <h4>Why Choose Formax Cloud?</h4>
              <ul>
                <li>
                  <strong>Scalable Solutions:</strong> Whether you're a small
                  business or an enterprise, our Transaction SMS platform scales
                  to meet your needs.
                </li>
                <li>
                  <strong>Customizable Messaging:</strong> Tailor your messages
                  to fit your business requirements, ensuring relevant and
                  timely communication with your users.
                </li>
                <li>
                  <strong>Reliable Infrastructure:</strong> Our robust
                  infrastructure ensures high deliverability and no downtime, so
                  you never miss a message.
                </li>
                <li>
                  <strong>Affordable Pricing:</strong> We offer competitive
                  pricing packages to ensure you get the best service without
                  breaking the bank.
                </li>
              </ul>

              <div class="cta">
                <h4>Get Started with Transaction SMS</h4>
                <p>
                  Boost your business communication with secure, real-time
                  Transaction SMS. Contact <strong>Formax Cloud</strong> today
                  to integrate Transaction SMS services into your platform.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <SignupBanner />
      <Footer />
    </>
  );
};

export default TransactionalSms;
