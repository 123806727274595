import React from "react";

import Footer from "../Templete/Footer";
import Header from "../Templete/Header";

const TermsAndConditions = () => {
  return (
    <>
      <Header />

      <div className="rts-hosting-banner rts-hosting-banner-bg banner-default-height">
        <div className="container">
          <div className="row">
            <div className="banner-area">
              <div className="rts-hosting-banner rts-hosting-banner__content w-530">
                <span className="starting__price">Terms & Conditions </span>
                <h1 className="banner-title">Terms & Conditions</h1>
                <p className="slogan"></p>
              </div>
              <div className="rts-hosting-banner__image">
                <img src="/images/privacy-policy.png" alt="" width={550} />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="rts-knowledgebase pt--120">
        <div class="container">
          <div class="tab-content" id="myTabContent">
            <div
              class="tab-pane fade active show knowledge__content"
              id="share-hosting-tab"
              role="tabpanel"
              aria-labelledby="share-hosting"
            >
              <h3>Terms and Conditions</h3>
              <p>Effective Date: 01.01.2023</p>

              <p>
                Welcome to{" "}
                <a href="https://www.formax.cloud">www.formax.cloud</a> (the
                "Website"). These Terms and Conditions ("Terms") govern your use
                of our Website operated by Formax IT Solutions Private Limited
                ("we", "us", or "our"). By accessing or using the Website, you
                agree to these Terms. If you do not agree to any part of these
                Terms, you should discontinue the use of our Website
                immediately.
              </p>

              <h5>1. Use of the Website</h5>
              <p>
                You agree to use the Website in compliance with these Terms and
                all applicable local, state, national, and international laws
                and regulations. You shall not use the Website for any unlawful
                purpose, including but not limited to:
              </p>
              <ul>
                <li>
                  Posting or transmitting any unlawful, harmful, threatening,
                  defamatory, or objectionable content
                </li>
                <li>
                  Engaging in conduct that could damage, disable, or impair the
                  functionality of the Website
                </li>
                <li>
                  Attempting to gain unauthorized access to any part of the
                  Website or its related systems
                </li>
                <li>
                  Distributing viruses, malware, or any other harmful software
                </li>
              </ul>

              <h5>2. Intellectual Property</h5>
              <p>
                All content on this Website, including text, graphics, logos,
                images, and software, is the property of Formax IT Solutions
                Private Limited or its content suppliers and is protected by
                applicable copyright, trademark, and intellectual property laws.
                You may not use, reproduce, distribute, or exploit any content
                from the Website without our prior written consent.
              </p>

              <h5>3. User-Generated Content</h5>
              <p>
                By submitting any content to the Website, including but not
                limited to comments, reviews, or feedback, you grant us a
                non-exclusive, royalty-free, perpetual, irrevocable, and fully
                sub-licensable right to use, reproduce, modify, adapt, publish,
                and distribute such content worldwide. You represent and warrant
                that you own or otherwise control all rights to the content you
                submit and that your content does not violate any third-party
                rights or applicable laws.
              </p>

              <h5>4. Third-Party Links</h5>
              <p>
                The Website may contain links to third-party websites or
                services that are not owned or controlled by Formax IT Solutions
                Private Limited. We have no control over and assume no
                responsibility for the content, privacy policies, or practices
                of any third-party websites. You acknowledge and agree that we
                are not liable for any loss or damage caused by your use of any
                third-party websites.
              </p>

              <h5>5. Disclaimer of Warranties</h5>
              <p>
                The Website is provided on an "as-is" and "as-available" basis.
                We make no representations or warranties of any kind, express or
                implied, regarding the operation of the Website or the
                information, content, or materials available on it. You
                expressly agree that your use of the Website is at your sole
                risk. To the fullest extent permitted by law, we disclaim all
                warranties, express or implied, including but not limited to
                implied warranties of merchantability, fitness for a particular
                purpose, and non-infringement.
              </p>

              <h5>6. Limitation of Liability</h5>
              <p>
                In no event shall Formax IT Solutions Private Limited, its
                directors, employees, or agents be liable for any direct,
                indirect, incidental, special, or consequential damages arising
                out of or in connection with your use of or inability to use the
                Website, even if we have been advised of the possibility of such
                damages. This limitation applies to all claims, including but
                not limited to loss of profits, data loss, or any other tangible
                or intangible losses.
              </p>

              <h5>7. Indemnification</h5>
              <p>
                You agree to indemnify, defend, and hold harmless Formax IT
                Solutions Private Limited, its affiliates, and their respective
                officers, directors, employees, and agents from any claims,
                liabilities, damages, losses, costs, or expenses, including
                reasonable attorneys' fees, arising out of your violation of
                these Terms or your use of the Website.
              </p>

              <h5>8. Termination</h5>
              <p>
                We reserve the right, in our sole discretion, to terminate or
                suspend your access to the Website at any time, with or without
                notice, for any reason, including but not limited to a breach of
                these Terms.
              </p>

              <h5>9. Governing Law</h5>
              <p>
                These Terms and your use of the Website shall be governed by and
                construed in accordance with the laws of India, without regard
                to its conflict of law principles. Any disputes arising out of
                or in connection with these Terms shall be subject to the
                exclusive jurisdiction of the courts in Kolkata, India.
              </p>

              <h5>10. Changes to These Terms</h5>
              <p>
                We reserve the right to update or modify these Terms at any
                time. Any changes will be posted on this page, and the
                "Effective Date" at the top of the Terms will be updated
                accordingly. Your continued use of the Website after any changes
                to these Terms constitutes your acceptance of the new Terms.
              </p>

              <h5>11. Contact Us</h5>
              <p>
                {" "}
                If you have any questions about this Terms & Conditions, please
                contact us by email: support@formax.cloud.
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default TermsAndConditions;
