import React from "react";

const OurServices = () => {
  return (
    <>
      {/* HOSTING OPTION */}
      <div className="rts-hosting-type">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="rts-hosting-type__section text-center">
                <h3
                  className="rts-section__title"
                  // data-sal="slide-down"
                  // data-sal-delay={100}
                  // data-sal-duration={800}
                >
                  We Provide Messaging Solution
                </h3>
                <p
                //   data-sal="slide-down"
                //   data-sal-delay={400}
                //   data-sal-duration={800}
                >
                  Comprehensive Messaging Services to Empower Your Business
                </p>
              </div>
            </div>
          </div>
          {/* hosting option */}
          <div className="row">
            <div className="col-lg-12">
              <div className="rts-hosting-type__slider">
                <div className="swiper-wrapper">
                  {/* single package */}
                  <div className="swiper-slide">
                    <div className="rts-hosting-type__single">
                      <div className="hosting-icon">
                        <img src="/images/alert-sms.png" alt="" />
                      </div>
                      <a href="/transactional-sms" className="title">
                        Transactional SMS
                      </a>
                      <p className="excerpt">
                        Reliable Transactional SMS for Critical Updates and
                        Notifications
                      </p>

                      <a
                        href="/transactional-sms"
                        className="primary__btn border__btn"
                      >
                        View Details{" "}
                        <i className="fa-light fa-long-arrow-right" />
                      </a>
                    </div>
                  </div>
                  {/* single package end */}
                  {/* single package */}
                  <div className="swiper-slide">
                    <div className="rts-hosting-type__single">
                      <div className="hosting-icon">
                        <img src="/images/otp-sms.png" alt="" />
                      </div>
                      <a href="/otp-sms" className="title">
                        OTP SMS
                      </a>
                      <p className="excerpt">
                        Fast and Reliable OTP SMS for Seamless Two Step
                        Verification
                      </p>

                      <a href="/otp-sms" className="primary__btn border__btn">
                        View Details{" "}
                        <i className="fa-light fa-long-arrow-right" />
                      </a>
                    </div>
                  </div>
                  {/* single package end */}
                  {/* single package */}
                  <div className="swiper-slide">
                    <div className="rts-hosting-type__single">
                      <div className="hosting-icon">
                        <img src="/images/sms.png" alt="" />
                      </div>
                      <a href="/promotional-sms" className="title">
                        Promotional SMS
                      </a>
                      <p className="excerpt">
                        Engaging Promotional SMS to Boost Your Marketing
                        Campaigns
                      </p>

                      <a
                        href="/promotional-sms"
                        className="primary__btn border__btn"
                      >
                        View Details{" "}
                        <i className="fa-light fa-long-arrow-right" />
                      </a>
                    </div>
                  </div>
                  {/* single package end */}
                  {/* single package */}
                  <div className="swiper-slide">
                    <div className="rts-hosting-type__single">
                      <div className="hosting-icon">
                        <img src="/images/web.png" alt="" />
                      </div>
                      <a href="/sms-reseller" className="title">
                        SMS Reseller
                      </a>
                      <p className="excerpt">
                        Start Your Own SMS Business with Our SMS Reseller
                        Program
                      </p>

                      <a
                        href="/sms-reseller"
                        className="primary__btn border__btn"
                      >
                        View Details{" "}
                        <i className="fa-light fa-long-arrow-right" />
                      </a>
                    </div>
                  </div>
                  {/* single package end */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* HOSTING OPTION END */}
    </>
  );
};

export default OurServices;
