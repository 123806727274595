import React from "react";

import Footer from "../Templete/Footer";
import Header from "../Templete/Header";
import SignupBanner from "../Home/SignupBanner";

const PromotionalSms = () => {
  return (
    <>
      <Header />

      <div className="rts-hosting-banner rts-hosting-banner-bg banner-default-height">
        <div className="container">
          <div className="row">
            <div className="banner-area">
              <div className="rts-hosting-banner rts-hosting-banner__content w-530">
                <span className="starting__price">Promotional SMS </span>
                <h1 className="banner-title">Promotional SMS</h1>
                <p className="slogan">
                  Boost Engagement with Targeted, High-Impact Promotional
                  Messages.
                </p>
              </div>
              <div className="rts-hosting-banner__image">
                <img
                  src="/images/promotional-sms-banner.png"
                  alt=""
                  width={550}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="rts-knowledgebase pt--120 pb--120">
        <div class="container">
          <div class="tab-content" id="myTabContent">
            <div
              class="tab-pane fade active show knowledge__content"
              id="share-hosting-tab"
              role="tabpanel"
              aria-labelledby="share-hosting"
            >
              <h4>Promotional SMS - Reach, Engage, and Convert</h4>
              <p>
                Maximize your business’s reach and engagement with our tailored
                Promotional SMS service at <strong>Formax Cloud</strong>.
                Whether you want to promote special offers, new product
                launches, or seasonal sales, our Promotional SMS platform
                enables you to deliver targeted and impactful messages to your
                customers, helping you grow your business effectively.
              </p>

              <h4>Benefits of Using Our Promotional SMS Service</h4>
              <ul>
                <li>
                  <strong>Wide Reach:</strong> Instantly reach thousands of
                  potential customers with a single click, regardless of their
                  location.
                </li>
                <li>
                  <strong>Cost-Effective Marketing:</strong> SMS campaigns are a
                  highly cost-effective way to promote your business, ensuring
                  maximum ROI with minimal investment.
                </li>
                <li>
                  <strong>Customizable Messaging:</strong> Tailor your
                  promotional messages to specific audience segments for a more
                  personalized and relevant experience.
                </li>
                <li>
                  <strong>High Open Rates:</strong> SMS messages have a
                  significantly higher open rate compared to emails, ensuring
                  that your promotions are seen by more people.
                </li>
                <li>
                  <strong>Instant Delivery:</strong> With our fast and reliable
                  infrastructure, your promotional messages are delivered to
                  your audience in real-time, driving immediate action.
                </li>
                <li>
                  <strong>DLT Compliant:</strong> Our platform adheres to DLT
                  regulations, ensuring your promotional campaigns are compliant
                  with industry standards.
                </li>
              </ul>

              <h4>Promote with Confidence</h4>
              <p>Our Promotional SMS service is perfect for promoting:</p>
              <ul>
                <li>Limited-time offers and discounts</li>
                <li>New product or service launches</li>
                <li>Seasonal sales and festive promotions</li>
                <li>Event invitations and reminders</li>
                <li>Brand awareness campaigns</li>
              </ul>

              <h4>Why Choose Formax Cloud?</h4>
              <ul>
                <li>
                  <strong>Targeted Campaigns:</strong> Reach the right audience
                  by creating targeted campaigns based on location, interests,
                  and behavior.
                </li>
                <li>
                  <strong>Real-Time Reporting:</strong> Monitor your campaign’s
                  success with real-time reports on delivery status and customer
                  engagement.
                </li>
                <li>
                  <strong>Bulk Messaging:</strong> Effortlessly send messages to
                  large audiences with our reliable bulk SMS platform.
                </li>
                <li>
                  <strong>24/7 Support:</strong> Our team of experts is
                  available round-the-clock to assist you with your promotional
                  SMS campaigns.
                </li>
              </ul>

              <div class="cta">
                <h4>Start Your Promotional SMS Campaign Today</h4>
                <p>
                  Unlock the power of SMS marketing and engage with your
                  customers like never before. Contact{" "}
                  <strong>Formax Cloud</strong> to launch your next promotional
                  SMS campaign.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <SignupBanner />
      <Footer />
    </>
  );
};

export default PromotionalSms;
