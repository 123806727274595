import React from "react";

const Faq = () => {
  return (
    <>
      {/* FAQ */}
      <section className="rts-faq section__padding">
        <div className="container">
          <div className="row">
            <div className="col-lg-5">
              <div className="rts-faq__first">
                <h3
                  className="title"
                  //   data-sal="slide-down"
                  //   data-sal-delay={300}
                  //   data-sal-duration={800}
                >
                  Got questions? Well, we've got answers.
                </h3>
                <p
                  style={{ marginBottom: "50px" }}
                  //   data-sal="slide-down"
                  //   data-sal-delay={400}
                  //   data-sal-duration={800}
                >
                  Find answers to frequently asked questions about our world
                  class messaging solutions.
                </p>
                <img
                  //   data-sal="slide-down"
                  //   data-sal-delay={500}
                  //   data-sal-duration={800}
                  src="/images/faq.png"
                  alt="faq"
                />
              </div>
            </div>
            <div className="col-lg-6 offset-lg-1">
              <div className="rts-faq__accordion">
                <div className="accordion accordion-flush" id="rts-accordion">
                  <div
                    className="accordion-item active"
                    // data-sal="slide-left"
                    // data-sal-delay={300}
                    // data-sal-duration={800}
                  >
                    <div className="accordion-header" id="first">
                      <h4
                        className="accordion-button collapse show"
                        data-bs-toggle="collapse"
                        data-bs-target="#item__one"
                        aria-expanded="false"
                        aria-controls="item__one"
                      >
                        What types of SMS services do you provide?
                      </h4>
                    </div>
                    <div
                      id="item__one"
                      className="accordion-collapse collapse collapse show"
                      aria-labelledby="first"
                      data-bs-parent="#rts-accordion"
                    >
                      <div className="accordion-body">
                        We offer a wide range of SMS services, including
                        Transactional SMS, Promotional SMS, OTP SMS, and
                        Reseller SMS solutions. Our platform ensures fast
                        delivery, reliability, and easy API integration.
                      </div>
                    </div>
                  </div>
                  <div
                    className="accordion-item"
                    // data-sal="slide-left"
                    // data-sal-delay={400}
                    // data-sal-duration={800}
                  >
                    <div className="accordion-header" id="two">
                      <h4
                        className="accordion-button collapsed"
                        data-bs-toggle="collapse"
                        data-bs-target="#item__two"
                        aria-expanded="false"
                        aria-controls="item__two"
                      >
                        What is the difference between Transactional and
                        Promotional SMS?
                      </h4>
                    </div>
                    <div
                      id="item__two"
                      className="accordion-collapse collapse"
                      aria-labelledby="two"
                      data-bs-parent="#rts-accordion"
                    >
                      <div className="accordion-body">
                        Transactional SMS is used to send important,
                        time-sensitive information like OTPs, order updates, or
                        alerts. Promotional SMS is for marketing purposes, such
                        as offers, discounts, and general promotions to boost
                        engagement.
                      </div>
                    </div>
                  </div>
                  <div
                    className="accordion-item"
                    // data-sal="slide-left"
                    // data-sal-delay={500}
                    // data-sal-duration={800}
                  >
                    <div className="accordion-header" id="three">
                      <h4
                        className="accordion-button collapsed"
                        data-bs-toggle="collapse"
                        data-bs-target="#item__three"
                        aria-expanded="false"
                        aria-controls="item__three"
                      >
                        How secure is your platform for sending OTP SMS?
                      </h4>
                    </div>
                    <div
                      id="item__three"
                      className="accordion-collapse collapse"
                      aria-labelledby="three"
                      data-bs-parent="#rts-accordion"
                    >
                      <div className="accordion-body">
                        Our platform ensures high-level encryption and security
                        for all OTP messages, providing instant and secure
                        delivery for sensitive customer verification.
                      </div>
                    </div>
                  </div>
                  <div
                    className="accordion-item"
                    // data-sal="slide-left"
                    // data-sal-delay={600}
                    // data-sal-duration={800}
                  >
                    <div className="accordion-header" id="four">
                      <h4
                        className="accordion-button collapsed"
                        data-bs-toggle="collapse"
                        data-bs-target="#item__four"
                        aria-expanded="false"
                        aria-controls="item__four"
                      >
                        Do you offer SMS reselling options?
                      </h4>
                    </div>
                    <div
                      id="item__four"
                      className="accordion-collapse collapse"
                      aria-labelledby="four"
                      data-bs-parent="#rts-accordion"
                    >
                      <div className="accordion-body">
                        Yes, we have a comprehensive SMS reseller program that
                        allows businesses to offer SMS services to their clients
                        under their own brand, with flexible pricing and account
                        management tools.
                      </div>
                    </div>
                  </div>

                  <div
                    className="accordion-item"
                    // data-sal="slide-left"
                    // data-sal-delay={600}
                    // data-sal-duration={800}
                  >
                    <div className="accordion-header" id="five">
                      <h4
                        className="accordion-button collapsed"
                        data-bs-toggle="collapse"
                        data-bs-target="#item__five"
                        aria-expanded="false"
                        aria-controls="item__five"
                      >
                        How can I track the delivery of my SMS messages?
                      </h4>
                    </div>
                    <div
                      id="item__five"
                      className="accordion-collapse collapse"
                      aria-labelledby="five"
                      data-bs-parent="#rts-accordion"
                    >
                      <div className="accordion-body">
                        Our platform provides real-time delivery reports,
                        allowing you to track the status of every message sent
                        and monitor its performance with detailed analytics.
                      </div>
                    </div>
                  </div>

                  <div
                    className="accordion-item"
                    // data-sal="slide-left"
                    // data-sal-delay={600}
                    // data-sal-duration={800}
                  >
                    <div className="accordion-header" id="six">
                      <h4
                        className="accordion-button collapsed"
                        data-bs-toggle="collapse"
                        data-bs-target="#item__six"
                        aria-expanded="false"
                        aria-controls="item__six"
                      >
                        Is there a setup fee for getting started?
                      </h4>
                    </div>
                    <div
                      id="item__six"
                      className="accordion-collapse collapse"
                      aria-labelledby="five"
                      data-bs-parent="#rts-accordion"
                    >
                      <div className="accordion-body">
                        No, there are no setup fees. You can start using our SMS
                        services without any upfront costs. Simply create an
                        account and begin sending messages instantly.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* FAQ END */}
    </>
  );
};

export default Faq;
