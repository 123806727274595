import React from "react";

const OurFeatures = () => {
  return (
    <>
      {/* HOSTING FEATURE FOUR */}
      <div className="rts-hosting-feature-five pt--120 pb--120">
        <div className="container">
          <div className="section-inner">
            <div className="row g-30 justify-content-md-center align-items-center">
              <div className="col-lg-6 col-md-10 col-xl-5">
                <div className="rts-section section-style-two">
                  <div className="rts-section__two">
                    <h3 className="title mb-0">
                      What Sets Formax Cloud Apart from the Competition
                    </h3>
                  </div>
                  <div className="rts-section-description">
                    <p className="description">
                      Discover the unique features and advantages that make
                      Formax Cloud the superior choice for all your SMS needs.
                      From unmatched reliability and security to flexible
                      solutions and exceptional support, find out why our
                      platform stands out in the industry.
                    </p>
                  </div>
                </div>
                <ul className="feature-list-area">
                  <li className="list-wrapper">
                    <div className="icon">
                      <img src="assets/images/feature/feature-05.svg" alt="" />
                    </div>
                    <div className="content">
                      <h4 className="title">99.9% Gateway Uptime</h4>
                      <p className="desc">
                        Enjoy consistent performance with our highly reliable
                        gateway, offering 99.9% uptime to ensure your messages
                        are delivered without interruption.
                      </p>
                    </div>
                  </li>
                  <li className="list-wrapper">
                    <div className="icon">
                      <img src="assets/images/feature/feature-06.svg" alt="" />
                    </div>
                    <div className="content">
                      <h4 className="title">Safe, Secure, and Reliable</h4>
                      <p className="desc">
                        Count on our platform for data security, message
                        encryption, and a reliable SMS delivery network to
                        ensure safe communication.
                      </p>
                    </div>
                  </li>
                  <li className="list-wrapper">
                    <div className="icon">
                      <img src="assets/images/feature/feature-07.svg" alt="" />
                    </div>
                    <div className="content">
                      <h4 className="title">Dedicated Support 24/7</h4>
                      <p className="desc">
                        Our dedicated support team is available around the clock
                        to assist with any queries or technical issues.
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
              <div className="col-lg-6 col-md-10 col-xl-6 offset-xl-1">
                <div className="right-side-image">
                  <img
                    src="https://img.freepik.com/free-photo/happy-female-entrepreneur-with-headset-drinking-coffee-while-surfing-net-touchpad-office_637285-1983.jpg"
                    alt=""
                  />
                  <div className="image-shape-content">
                    <img
                      src="assets/images/feature/feature__image-small.png"
                      alt=""
                    />
                    <div className="img-content">
                      <h3>
                        <span className="counter">10</span>
                        <span>M+</span>
                      </h3>
                      <p>SMS Delivered Daily</p>
                      <img
                        src="assets/images/feature/crystal.svg"
                        alt=""
                        className="shape-img"
                      />
                    </div>
                  </div>
                  <img
                    src="assets/images/feature/crystal-2.svg"
                    alt=""
                    className="shape-img2"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <img
          className="feature-shape-img2"
          src="assets/images/testimonials/bg-shape-3.svg"
          alt=""
        />
        <img
          src="assets/images/feature/feature-bg-shape.svg"
          alt=""
          className="feature-shape-img"
        />
      </div>
      {/* HOSTING FEATURE FOUR END */}
    </>
  );
};

export default OurFeatures;
