import React from "react";

import Footer from "../Templete/Footer";
import Header from "../Templete/Header";
import OurFeatures from "../Home/OurFeatures";
import SignupBanner from "../Home/SignupBanner";

const AboutUs = () => {
  return (
    <>
      <Header />
      <>
        {/* shared hosting banner */}
        <div className="rts-hosting-banner rts-hosting-banner-bg banner-default-height">
          <div className="container">
            <div className="row">
              <div className="banner-area">
                <div className="rts-hosting-banner rts-hosting-banner__content about__banner">
                  <span
                    className="starting__price"
                    // data-sal="slide-down"
                    // data-sal-delay={100}
                    // data-sal-duration={800}
                  >
                    About Formax Cloud
                  </span>
                  <h1
                    className="banner-title"
                    // data-sal="slide-down"
                    // data-sal-delay={200}
                    // data-sal-duration={800}
                  >
                    About Formax Cloud
                  </h1>
                  <p
                    className="slogan"
                    // data-sal="slide-down"
                    // data-sal-delay={300}
                    // data-sal-duration={800}
                  >
                    Connecting Businesses with Reliable Messaging Solutions
                  </p>
                </div>
                <div className="rts-hosting-banner__image about">
                  <img src="/images/about-us-4.jpg" width={562} alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* shared hosting banner end*/}
        {/* ABOUT RESELLER HOSTING */}
        <div className="rts-about-reseller section__padding">
          <div className="container">
            <div className="row">
              <div className="col-lg-5 padding-bottom-75">
                <div className="hosting-about-image-bg" />
                <div className="hosting-about-image">
                  <div className="img-one">
                    <img
                      src="assets/images/about/resell/image-1.png"
                      width={378}
                      height={400}
                      alt=""
                    />
                  </div>
                  <div className="img-two">
                    <img
                      src="assets/images/about/resell/image-2.png"
                      width={247}
                      height={347}
                      alt=""
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-7">
                <div className="hosting-info">
                  <h3
                    className="hosting-info__title"
                    // data-sal="slide-down"
                    // data-sal-delay={200}
                    // data-sal-duration={800}
                  >
                    Who we are
                  </h3>
                  <p
                    className="description"
                    style={{ textAlign: "justify" }}
                    // data-sal="slide-down"
                    // data-sal-delay={100}
                    // data-sal-duration={800}
                  >
                    At Formax Cloud, we are dedicated to transforming how
                    businesses communicate with their customers. As a leading
                    provider of SMS solutions, we specialize in delivering
                    high-performance services such as bulk SMS, transactional
                    messaging, OTP SMS, promotional campaigns, and SMS gateway
                    software. Our mission is to streamline your communication
                    processes, helping you engage your audience effectively
                    while ensuring fast and reliable message delivery every
                    time. Through innovation and a customer-first approach, we
                    offer the tools you need to enhance connectivity and elevate
                    your business communications.
                  </p>
                  <h4
                    className="hosting-info__title"
                    // data-sal="slide-down"
                    // data-sal-delay={200}
                    // data-sal-duration={800}
                  >
                    Our Vision
                  </h4>
                  <p
                    className="description"
                    style={{ textAlign: "justify" }}
                    // data-sal="slide-down"
                    // data-sal-delay={100}
                    // data-sal-duration={800}
                  >
                    Our vision at Formax Cloud is to simplify business
                    communications through robust and scalable SMS solutions. We
                    aim to become a strategic partner for companies that want to
                    improve their customer interactions, enhance security
                    protocols, and run successful marketing campaigns via SMS.
                    Our solutions are designed with innovation, reliability, and
                    customer satisfaction at the core.
                  </p>
                  <h4
                    className="hosting-info__title"
                    // data-sal="slide-down"
                    // data-sal-delay={200}
                    // data-sal-duration={800}
                  >
                    Our Commitment
                  </h4>
                  <p
                    className="description"
                    style={{ textAlign: "justify" }}
                    // data-sal="slide-down"
                    // data-sal-delay={100}
                    // data-sal-duration={800}
                  >
                    At Formax Cloud, we are more than just a service provider;
                    we are your partners in communication. We understand the
                    critical role SMS plays in your business, whether for
                    marketing, customer engagement, or transactional security.
                    That’s why we strive to deliver the most reliable and
                    innovative SMS solutions, tailored to your needs, ensuring
                    that your business can communicate effectively with its
                    customers at any time. Join hands with Formax Cloud and take
                    your business communications to the next level with our
                    comprehensive and reliable SMS services. Whether you’re
                    looking to enhance customer engagement, streamline
                    operations, or secure your transactions, we have the
                    solutions you need.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* ABOUT RESELLER HOSTING END */}

        <OurFeatures />

        <SignupBanner />
      </>
      <Footer />
    </>
  );
};

export default AboutUs;
