import React from "react";

const Testimonial = () => {
  return (
    <>
      {/* TESTIMONIAL */}
      <section className="rts-testimonial section__padding">
        <div className="container">
          <div className="row ">
            <div className="col-12 d-flex justify-content-center">
              <div className="rts-section w-460 text-center">
                <h3
                  className="rts-section__title"
                  //   data-sal="slide-down"
                  //   data-sal-delay={300}
                  //   data-sal-duration={800}
                >
                  Our Customers Love Us
                </h3>
                <p
                  className="rts-section__description"
                  //   data-sal="slide-down"
                  //   data-sal-delay={400}
                  //   data-sal-duration={800}
                >
                  See Why Our Clients Rave About Our SMS Solutions
                </p>
              </div>
            </div>
          </div>
          {/* testimonial */}
          <div className="row">
            <div className="col-lg-12">
              <div className="rts-testimonial__slider testimonial__slider--first">
                <div className="swiper-wrapper">
                  {/* single testimonial */}
                  <div className="swiper-slide">
                    <div className="rts-testimonial__single">
                      <div className="rating">
                        <i className="fa-solid fa-star" />
                        <i className="fa-solid fa-star" />
                        <i className="fa-solid fa-star" />
                        <i className="fa-solid fa-star" />
                        <i className="fa-solid fa-star" />
                        <i className="fa-solid fa-star" />
                      </div>
                      <div className="content">
                        <p>
                          We've been using their SMS solutions for over a year
                          now, and the reliability and support have been
                          outstanding. Highly recommend for anyone needing
                          seamless messaging!
                        </p>
                      </div>
                      <div className="author__meta">
                        <div className="author__meta--image">
                          <img src="/images/user-icon.png" alt="" width={45} />
                        </div>
                        <div className="author__meta--details">
                          <a href="#">Amit Sharma</a>
                          <span>Business Owner</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* single testimonial end */}
                  {/* single testimonial */}
                  <div className="swiper-slide">
                    <div className="rts-testimonial__single">
                      <div className="rating">
                        <i className="fa-solid fa-star" />
                        <i className="fa-solid fa-star" />
                        <i className="fa-solid fa-star" />
                        <i className="fa-solid fa-star" />
                        <i className="fa-solid fa-star" />
                        <i className="fa-solid fa-star" />
                      </div>
                      <div className="content">
                        <p>
                          The transactional SMS service has transformed how we
                          communicate with our customers. Fast delivery and
                          excellent customer support by Formax Cloud make them
                          our go-to choice.
                        </p>
                      </div>
                      <div className="author__meta">
                        <div className="author__meta--image">
                          <img src="/images/user-icon.png" alt="" width={45} />
                        </div>
                        <div className="author__meta--details">
                          <a href="#">Priya Singh</a>
                          <span>Chartered Accountant</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* single testimonial end */}
                  {/* single testimonial */}
                  <div className="swiper-slide">
                    <div className="rts-testimonial__single">
                      <div className="rating">
                        <i className="fa-solid fa-star" />
                        <i className="fa-solid fa-star" />
                        <i className="fa-solid fa-star" />
                        <i className="fa-solid fa-star" />
                        <i className="fa-solid fa-star" />
                        <i className="fa-solid fa-star" />
                      </div>
                      <div className="content">
                        <p>
                          The promotional SMS campaigns have significantly
                          boosted our engagement rates. The platform is
                          user-friendly, and the team is always ready to help
                          with any queries.
                        </p>
                      </div>
                      <div className="author__meta">
                        <div className="author__meta--image">
                          <img src="/images/user-icon.png" alt="" width={45} />
                        </div>
                        <div className="author__meta--details">
                          <a href="#">Samira Khan</a>
                          <span>Digital Marketer</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* single testimonial end */}
                  {/* single testimonial */}
                  <div className="swiper-slide">
                    <div className="rts-testimonial__single">
                      <div className="rating">
                        <i className="fa-solid fa-star" />
                        <i className="fa-solid fa-star" />
                        <i className="fa-solid fa-star" />
                        <i className="fa-solid fa-star" />
                        <i className="fa-solid fa-star" />
                        <i className="fa-solid fa-star" />
                      </div>
                      <div className="content">
                        <p>
                          We've seen a remarkable improvement in our marketing
                          results since using their promotional SMS services.
                          The platform is intuitive, and the results speak for
                          themselves.
                        </p>
                      </div>
                      <div className="author__meta">
                        <div className="author__meta--image">
                          <img src="/images/user-icon.png" alt="" width={45} />
                        </div>
                        <div className="author__meta--details">
                          <a href="#">Sonia Mehta</a>
                          <span>IT Specialist</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* single testimonial end */}
                </div>
                {/* pagination dot */}
                <div className="rts-dot__button slider-center" />
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* TESTIMONIAL END */}
    </>
  );
};

export default Testimonial;
