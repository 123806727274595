import React from "react";

import Footer from "../Templete/Footer";
import Header from "../Templete/Header";

const RefundPolicy = () => {
  return (
    <>
      <Header />

      <div className="rts-hosting-banner rts-hosting-banner-bg banner-default-height">
        <div className="container">
          <div className="row">
            <div className="banner-area">
              <div className="rts-hosting-banner rts-hosting-banner__content w-530">
                <span className="starting__price">Refund Policy </span>
                <h1 className="banner-title">Refund Policy</h1>
                <p className="slogan"></p>
              </div>
              <div className="rts-hosting-banner__image">
                <img src="/images/privacy-policy.png" alt="" width={550} />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="rts-knowledgebase pt--120">
        <div class="container">
          <div class="tab-content" id="myTabContent">
            <div
              class="tab-pane fade active show knowledge__content"
              id="share-hosting-tab"
              role="tabpanel"
              aria-labelledby="share-hosting"
            >
              <h3>Refund Policy</h3>
              <p>Effective Date: 01.01.2023</p>

              <p>
                Thank you for choosing Formax IT Solutions Private Limited
                ("we", "us", or "our"). We value our customers and strive to
                provide the best services. This Refund Policy explains the terms
                under which we offer refunds for purchases made on or through{" "}
                <a href="https://www.formax.cloud">www.formax.cloud</a> (the
                "Website"). By making a purchase on our Website, you agree to
                this Refund Policy.
              </p>

              <h5>1. Refund Eligibility</h5>
              <p>
                Refunds will only be considered under the following
                circumstances:
              </p>
              <ul>
                <li>
                  If the service was not provided as described or as per the
                  agreed terms.
                </li>
                <li>
                  If there was a technical issue from our side preventing the
                  successful delivery of the service.
                </li>
                <li>
                  If the service provided contains errors or malfunctions that
                  we are unable to resolve within a reasonable time.
                </li>
                <li>
                  If you cancel the service before the development or
                  implementation process has begun.
                </li>
              </ul>

              <h5>2. Services Excluded from Refunds</h5>
              <p>The following services are non-refundable:</p>
              <ul>
                <li>
                  Any service where work has been completed, delivered, or is in
                  the final stages of completion.
                </li>
                <li>
                  Monthly or annual subscription fees once the service has been
                  provided or access granted.
                </li>
                <li>
                  Custom software or mobile app development services once the
                  project has been initiated.
                </li>
                <li>
                  Bulk SMS, email campaigns, or any marketing services where the
                  campaign has been initiated or services delivered.
                </li>
              </ul>

              <h5>3. Refund Process</h5>
              <p>
                To request a refund, please contact us at{" "}
                <a href="mailto:support@formax.cloud">support@formax.cloud</a>{" "}
                with the following details:
              </p>
              <ul>
                <li>Your name and contact information</li>
                <li>Details of the service purchased</li>
                <li>The reason for requesting a refund</li>
                <li>Supporting documentation or screenshots if applicable</li>
              </ul>
              <p>
                We will review your request and respond within 7 business days.
                If your request is approved, the refund will be processed within
                14 business days through the original payment method used for
                the purchase.
              </p>

              <h5>4. Partial Refunds</h5>
              <p>
                In certain cases, we may offer partial refunds, depending on the
                extent of the work already completed and the reason for the
                refund request. This will be determined on a case-by-case basis.
              </p>

              <h5>5. Cancellations</h5>
              <p>
                You may cancel a service at any time before the development or
                implementation process begins and receive a full refund.
                However, once the work has been initiated, refunds will not be
                issued unless agreed upon in writing by both parties.
              </p>

              <h5>6. Changes to Services</h5>
              <p>
                If you request changes to the scope of the service after work
                has begun, additional charges may apply. Any changes made after
                service initiation will not be eligible for a refund unless it
                meets the criteria outlined in the "Refund Eligibility" section.
              </p>

              <h5>7. No Refunds for Delays Caused by Client</h5>
              <p>
                We are not responsible for delays caused by clients, such as
                late submission of required information or approvals. In such
                cases, refunds will not be issued, and the project timeline may
                be extended accordingly.
              </p>

              <h5>8. Third-Party Services</h5>
              <p>
                If the services include third-party integrations or products,
                refunds will be subject to the third-party provider's terms and
                conditions. We are not responsible for third-party services and
                cannot issue refunds for their charges.
              </p>

              <h5>9. Disputes and Chargebacks</h5>
              <p>
                We take refund requests and client satisfaction seriously. If
                you are dissatisfied with our service, please contact us first
                to resolve the issue. Initiating chargebacks through your
                payment provider without first contacting us will result in
                termination of service, and you may be blacklisted from future
                services.
              </p>

              <h5>10. Contact Us</h5>
              <p>
                {" "}
                If you have any questions about this Terms & Conditions, please
                contact us by email: support@formax.cloud.
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default RefundPolicy;
