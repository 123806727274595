import React from "react";

const SignupBanner = () => {
  return (
    <>
      {/* CTA AREA */}
      <div className="rts-cta">
        <div className="container">
          <div className="row">
            <div className="rts-cta__wrapper">
              <div className="rts-cta__left">
                <h3
                  className="cta__title"
                  //   data-sal="slide-down"
                  //   data-sal-delay={300}
                  //   data-sal-duration={800}
                >
                  Experience the Power of Formax Cloud Today!
                </h3>
                <p
                //   data-sal="slide-down"
                //   data-sal-delay={400}
                //   data-sal-duration={800}
                >
                  Our priority is delivering exceptional SMS solutions tailored
                  to your needs. From instant OTPs to engaging promotional
                  messages, we ensure seamless communication every time.
                </p>
                <a
                  //   data-sal="slide-down"
                  //   data-sal-delay={500}
                  //   data-sal-duration={800}
                  href="https://app.formax.cloud/#/signup"
                  target="_blank"
                  className="primary__btn secondary__bg"
                >
                  get started <i className="fa-light fa-arrow-right" />
                </a>
              </div>
              <div className="rts-cta__right">
                <div className="cta-image">
                  <div className="cta-image__two">
                    <img src="assets/images/cta/cta__person.svg" alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* CTA AREA END  */}
    </>
  );
};

export default SignupBanner;
