import React from "react";
import { Link } from "react-router-dom";

const HeroBanner = () => {
  return (
    <React.Fragment>
      {/* HERO BANNER ONE */}
      <section className="rts-hero rts-hero__one banner-style-home-one">
        <div className="container">
          <div className="rts-hero__blur-area" />
          <div className="row align-items-end position-relative">
            <div className="col-lg-7">
              <div className="rts-hero__content w-550">
                <h6
                // data-sal="slide-down"
                // data-sal-delay={300}
                // data-sal-duration={800}
                >
                  <img
                    src="assets/images/icon/free.png"
                    alt="free"
                    width={25}
                  />
                  Free Developer API
                </h6>
                <h3
                  className="heading"
                  style={{ color: "white" }}
                  // data-sal="slide-down"
                  // data-sal-delay={300}
                  // data-sal-duration={800}
                >
                  Seamless Messaging Solutions
                </h3>
                <p
                  className="description"
                  style={{ textAlign: "justify" }}
                  // data-sal="slide-down"
                  // data-sal-delay={400}
                  // data-sal-duration={800}
                >
                  From secure OTPs and critical transaction alerts to engaging
                  promotional SMS and easy-to-use developer APIs, Formax Cloud
                  ensures fast, reliable, and scalable communication solutions
                  designed to meet your unique business demands.
                </p>
                <div
                  className="rts-hero__content--group"
                  // data-sal="slide-down"
                  // data-sal-delay={500}
                  // data-sal-duration={800}
                >
                  <a
                    href="https://app.formax.cloud/#/signup"
                    target="_blank"
                    className="rts-btn btn__long white__bg"
                  >
                    Sign Up <i class="fa-solid fa-right-to-bracket"></i>
                  </a>
                  <a
                    href="https://api.whatsapp.com/send?phone=919836114520&text=https://wa.me/919836114520?text=Hi%20Formax%20Cloud%20Team,%20I%20need%20some%20help."
                    target="_blank"
                    className="rts-btn btn__long border__white white__color"
                  >
                    Talk To Us
                  </a>
                </div>
                {/* <p
                // data-sal="slide-down"
                // data-sal-delay={600}
                // data-sal-duration={800}
                >
                  <img src="assets/images/icon/dollar.svg" alt="" />
                  Starting from 
                </p> */}
              </div>
            </div>
            <div className="col-lg-5">
              <div className="rts-hero__images position-relative">
                <div className="rts-hero-main">
                  <div className="image-main ">
                    <img
                      className="main top-bottom2"
                      src="images/our-services.png"
                      alt=""
                    />
                  </div>
                  <img
                    className="hero-shape one"
                    src="assets/images/banner/hosting.svg"
                    alt=""
                  />
                </div>
                <div className="rts-hero__images--shape">
                  <div className="one top-bottom">
                    <img src="assets/images/banner/left.svg" alt="" />
                  </div>
                  <div className="two bottom-top">
                    <img src="assets/images/banner/left.svg" alt="" />
                  </div>
                  <div className="three top-bottom">
                    <img src="assets/images/banner/top.svg" alt="" />
                  </div>
                  <div className="four bottom-top">
                    <img src="assets/images/banner/right.svg" alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* HERO BANNER ONE END */}
    </React.Fragment>
  );
};

export default HeroBanner;
